import { default as presetscal6h7rwXsMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue?macro=true";
import { default as _91slug_93hLi4EZUtPAMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue?macro=true";
import { default as indexoOh6AkBqmDMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue?macro=true";
import { default as searchrRI97c3qxwMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue?macro=true";
import { default as component_45stubvCsOzZ3XFUMeta } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvCsOzZ3XFU } from "/builds/buzzbrothers/skeleton/nuxt-skeleton/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_-presets___fr",
    path: "/_/presets",
    meta: presetscal6h7rwXsMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue")
  },
  {
    name: "_-presets___en",
    path: "/en/_/presets",
    meta: presetscal6h7rwXsMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/_/presets.vue")
  },
  {
    name: "slug___fr",
    path: "/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    meta: _91slug_93hLi4EZUtPAMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/[slug].vue")
  },
  {
    name: "index___fr",
    path: "/",
    meta: indexoOh6AkBqmDMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexoOh6AkBqmDMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/index.vue")
  },
  {
    name: "search___fr",
    path: "/search",
    meta: searchrRI97c3qxwMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchrRI97c3qxwMeta || {},
    component: () => import("/builds/buzzbrothers/skeleton/nuxt-skeleton/pages/search.vue")
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/fr-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  },
  {
    name: component_45stubvCsOzZ3XFUMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubvCsOzZ3XFU
  }
]