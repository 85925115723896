<template>
  <svg
    class="page-transition"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <path
      ref="elPath"
      class="_path"
      vector-effect="non-scaling-stroke"
      d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
    />
  </svg>
</template>

<script lang="ts" setup>
import { gsap as __gsap } from 'gsap'

import './pageTransition.bare.css'
import type { TPageTransitionExpose } from '~/components/pageTransition/pageTransition.type.ts'

defineExpose<TPageTransitionExpose>({
  leave,
  afterLeave,
  beforeEnter,
  enter,
})

const elPath = ref()

const paths = {
  step1: {
    unfilled: 'M 0 100 V 100 Q 50 100 100 100 V 100 z',
    inBetween: {
      curve1: 'M 0 100 V 50 Q 50 0 100 50 V 100 z',
      curve2: 'M 0 100 V 50 Q 50 100 100 50 V 100 z',
    },
    filled: 'M 0 100 V 0 Q 50 0 100 0 V 100 z',
  },
  step2: {
    filled: 'M 0 0 V 100 Q 50 100 100 100 V 0 z',
    inBetween: {
      curve1: 'M 0 0 V 50 Q 50 0 100 50 V 0 z',
      curve2: 'M 0 0 V 50 Q 50 100 100 50 V 0 z',
    },
    unfilled: 'M 0 0 V 0 Q 50 0 100 0 V 0 z',
  },
}

let isAnimating = false

function leave(done: void) {
  console.log('leave')
  if (isAnimating) return
  isAnimating = true

  __gsap
    .timeline({
      onComplete: () => {
        isAnimating = false
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step1.unfilled },
    })
    .to(
      elPath.value,
      {
        duration: 0.8,
        ease: 'power4.in',
        attr: { d: paths.step1.inBetween.curve1 },
      },
      0,
    )
    .to(elPath.value, {
      duration: 0.2,
      ease: 'power1',
      attr: { d: paths.step1.filled },
      onComplete: () => done(),
    })
}

function afterLeave() {
  console.log('afterLeave')
  if (isAnimating) return
  isAnimating = true
}

function beforeEnter() {
  console.log('beforeEnter')
}

function enter(done: void) {
  console.log('enter')
  if (isAnimating) return
  isAnimating = true

  __gsap
    .timeline({
      onComplete: () => {
        isAnimating = false
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step2.filled },
    })
    .to(elPath.value, {
      duration: 0.2,
      ease: 'sine.in',
      attr: { d: paths.step2.inBetween.curve1 },
    })
    .to(elPath.value, {
      duration: 1,
      ease: 'power4',
      attr: { d: paths.step2.unfilled },
    })

  // __gsap
  //   .timeline({
  //     onComplete: () => {
  //       isAnimating = false
  //     },
  //   })
  //   .set(elPath.value, {
  //     attr: { d: paths.step2.unfilled },
  //   })
  //   .to(
  //     elPath.value,
  //     {
  //       duration: 0.8,
  //       ease: 'power4.in',
  //       attr: { d: paths.step2.inBetween.curve2 },
  //     },
  //     0,
  //   )
  //   .to(elPath.value, {
  //     duration: 0.2,
  //     ease: 'power1',
  //     attr: { d: paths.step2.filled },
  //     onComplete: () => done(),
  //   })
  //   .set(elPath.value, {
  //     attr: { d: paths.step1.filled },
  //   })
  //   .to(elPath.value, {
  //     duration: 0.2,
  //     ease: 'sine.in',
  //     attr: { d: paths.step1.inBetween.curve2 },
  //   })
  //   .to(elPath.value, {
  //     duration: 1,
  //     ease: 'power4',
  //     attr: { d: paths.step1.unfilled },
  //   })
}
</script>
