<template>
  <span :class="`hotkey ${popover ? '-popover' : ''}`">
    {{ keys }}
  </span>
</template>
<script setup lang="ts">
import './hotkey.css'
import { type THotkey } from './hotkey.type'

const props = withDefaults(defineProps<THotkey>(), {})
</script>