import type { CookieRef } from '#app'
import { defineStore } from 'pinia'
import type { TUserCookiePayload } from '~/lib/user/user.type'
import { __deleteUserCookie } from '~/lib/user/user.utils.ts'
import { jwtDecode } from 'jwt-decode'

import __projectConfig from '~/project.config.ts'

export type TUserStore = {
  isLogged: ComputedRef<boolean>
  isLoginRequired: ComputedRef<boolean>
  apiToken: ComputedRef<string | null>
  login: (password: string) => Promise<TUserStoreLoginResponse>
  logout: () => Promise<TUserStoreLogoutResponse>
  restore: () => void
  setPreferences: (newPreferences: Partial<TUserStorePreferences>) => void
  preferences: ComputedRef<TUserStorePreferences>
}

export type TUserStorePreferences = {}

export type TUserStoreLoginResponse = {
  success: boolean
}

export type TUserStoreLogoutResponse = {
  success: boolean
}

export const useUserStore = defineStore('user', () => {
  const runtimeConfig = useRuntimeConfig()

  const appStore = useAppStore()

  let isLogged = ref(false),
    apiToken: Ref<string> = ref(runtimeConfig.public.apiPublicToken),
    defaultPreferences: TUserStorePreferences = {}

  let userCookie: CookieRef<string>,
    decodedUserCookie: TUserCookiePayload | null = null,
    userPreferencesCookie: CookieRef<TUserStorePreferences>

  function _refreshUserData(): void {
    // get the user cookie and decode it
    // but ONLY on preview mode
    if (appStore.stage === 'preview') {
      userCookie = useCookie(
        __projectConfig.cookies?.userCookieName ?? '__user',
      )
      isLogged.value = userCookie.value !== undefined
      if (userCookie.value) {
        decodedUserCookie = jwtDecode(
          userCookie.value as string,
        ) as TUserCookiePayload
        apiToken.value = decodedUserCookie.token
      }
    }

    // get user preferences if the user is logged in
    if (decodedUserCookie?.token) {
      userPreferencesCookie = useCookie<TUserStorePreferences>(
        __projectConfig.cookies?.userPreferencesCookieName ??
          '__userPreferences',
        {
          default: () => defaultPreferences,
          watch: true,
        },
      )
    }
  }

  /**
   * Restore the user state from the admin cookie.
   */
  function restore() {
    _refreshUserData()
    if (import.meta.browser && userPreferencesCookie?.value) {
      setPreferences(userPreferencesCookie.value)
    }
  }

  /**
   * Logs in the user as an admin.
   * @param password The password to use for the login
   */
  async function login(password: string): Promise<TUserStoreLoginResponse> {
    // try to login from the server
    const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
        }),
      }),
      data = await response.json()

    if (data.success) {
      document.location.reload()
      return {
        success: true,
      }
    }
    return {
      success: false,
    }
  }

  /**
   * Logs out the user.
   */
  async function logout(): Promise<TUserStoreLogoutResponse> {
    __deleteUserCookie()
    document.location.reload()
    return {
      success: true,
    }
  }

  /**
   * Set preferences
   */
  function setPreferences(
    newPreferences: Partial<TUserStorePreferences>,
  ): void {
    userPreferencesCookie.value = {
      ...userPreferencesCookie.value,
      ...newPreferences,
    }
  }

  // restore the user state
  restore()

  return {
    get isLogged() {
      return computed(() => isLogged.value)
    },
    get isLoginRequired() {
      return computed(() => appStore.stage === 'preview' && !isLogged.value)
    },
    get apiToken() {
      return computed(() => apiToken.value)
    },
    logout,
    login,
    restore,
    setPreferences,
    get preferences(): ComputedRef<TUserStorePreferences> {
      return computed(() => {
        return userPreferencesCookie?.value ?? defaultPreferences
      })
    },
  } as TUserStore
})
